<template>
    <div ref="panel" class="scroll-div" style="padding-top: 0;">
        <ts-table v-if="loaded" ref="table" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            zero-to-empty @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey"
            :summaryMethod="summaryFun" @handleRowDbClickChange="handleRowDbClickChange" hide-detail
            :style="{ height: realHeight + 'px' }" full @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
            </template>
            <template #left>
                <ts-table-operate :cols="cols" :table-name="tableName" @searchShowLeft="searchShowLeft"
                    @handleFilter="handleFilter" show-export @leadingOut="exportInfo()"
                    style="margin: 0 20px;align-items: center;">
                    <div>
                        （销售额单位：{{ $variable.unit }}）
                    </div>
                </ts-table-operate>
            </template>
        </ts-table>

        <div class="total-title__line"></div>
        <div class="total-title">
            销售数据趋势图
            <div class="total-title__right">筛选：
                <el-select v-model="canvas.model" filterable value-key="id" remote :remote-method="remoteMethod"
                    :loading="canvas.loading" @change="showProduct" style="width: 350px">
                    <el-option v-for="item in canvas.list" :key="item.id" :value="item"
                        :label="item.comName + ' ' + item.unifySpec">
                        {{ item.comName }}<span
                            style="font-size: 12px;color: #999;padding-left: 15px;">{{ item.unifySpec }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <ts-error v-if="canvas.message"></ts-error>
        <div v-else :id="nameKey" class="canvas small"></div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    props: ['compCode', 'baseQuery', 'visitId'],
    mixins: [myMixin],
    data() {
        return {
            nameKey: 'newCorpYearOne',
            tableName: 'sale_year',
            pageApi: 'company/saleYear/sumRateAndRatioGroupByComNameAndCompNameAndDoseAndSpecByYear',
            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug(),
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 60,
                    listOrderView: '是',
                    listClass: 'tc',
                    listQueryView: "是",
                    listQueryModel: 1
                },
                ...this.$variable.years.map(item => ({
                    fieldName: 'amount' + item.value,
                    name: item.value + '年',
                    listOrderView: '是',
                    width: 80,
                    listClass: 'tc'
                }))
            ]),
            active: '',
            error: '',
            canvas: {
                show: false,
                message: false,
                loading: false,
                list: [],
                model: {},
                code: 200,
            },

            loaded: false,
            realHeight: 100,
        }
    },
    created() {
        this.remoteMethod()
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
        this.loaded = true
    },
    methods: {
        remoteMethod(keyword) {
            this.canvas.loading = true
            this.$api.post(this.pageApi, {
                params: {
                    compCode: this.compCode,
                    comName: keyword ? `%${keyword}%` : undefined,
                },
                page: { pageNo: 1, pageSize: 20 }
            })
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.canvas.list = res.data.records.map(item => {
                            item.id = item.comCode + item.unifySpec
                            return item
                        })

                        if (!this.canvas.model.id && this.canvas.list.length !== 0) {
                            this.canvas.model = this.canvas.list[0]
                            this.showProduct()
                        }
                    } else {
                        this.canvas.message = true
                    }
                })
                .finally(() => {
                    this.canvas.loading = false
                })
        },
        showProduct() {
            const arr = this.$variable.years.map(item => {
                return {
                    year: this.$variable.yearMap[item.key],
                    amount: this.canvas.model['amount' + item.value],
                    rate: this.$help.parseFloat100(this.canvas.model['rate' + item.value]),
                }
            })
            this.$help.renderBar(this.nameKey, arr)
        },

        exportInfo(txt = '确定导出吗', query = {}) {
            this.$store.commit('confirm', {
                content: txt,
                callback: (next, back) => {
                    let search = this.getParam()
                    search = this.$help.joinObj(search, query)
                    this.$api.post('company/saleYear/exportSumRateAndRatioGroupByComNameAndCompNameAndDoseAndSpecByYear', search)
                        .then(res => {
                            if (!(res.success || res.code === 'N0001')) {
                                next()
                                this.$store.commit('error', res.message)
                            } else if (res.code === 'N0001') {
                                next()
                                this.exportInfo(res.message, { model: true })
                            } else {
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })
                            }
                        })
                        .catch(back)
                },
            })
        },
    }
}
</script>