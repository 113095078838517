<template>
    <div ref="panel" class="scroll-div" style="padding-top: 0;">
        <ts-table v-if="loaded" ref="table" :cols="cols" :data="list" :page-param="pageParam" :loading="loading"
            @handleSort="sort" :error="pageParam.error" :error-code="error" :name="nameKey" :summaryMethod="summaryFun"
            @handleRowDbClickChange="handleRowDbClickChange" hide-detail :style="{ height: realHeight + 'px' }" full
            @handleSizeChange="handleSizeChange" zero-to-empty @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
            </template>
            <template #left>
                <ts-table-operate :cols="cols" :table-name="tableName" :parent="_self" @searchShowLeft="searchShowLeft"
                    @handleFilter="handleFilter" show-export @leadingOut="exportInfo()"
                    style="margin: 0 20px;align-items: center;">
                    <div>
                        （销售额单位：{{ $variable.unit }}）
                    </div>
                </ts-table-operate>
            </template>
        </ts-table>

        <div class="total-title__line"></div>
        <div class="total-title">
            销售数据趋势图
            <div class="total-title__right">筛选：
                <el-select v-model="canvas.model" filterable remote :remote-method="remoteMethod"
                    :loading="canvas.loading" value-key="id" @change="showProduct" style="width: 350px">
                    <el-option v-for="item in canvas.list" :key="item.id" :value="item">{{ item.comName }}
                        <span style="font-size: 12px;color: #999;">
                            {{ item.unifyDose }}</span>
                        <span style="font-size: 12px;color: #999;padding-left: 5px;">
                            {{ item.unifySpec }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <ts-error v-if="canvas.message"></ts-error>
        <div :id="nameKey" class="canvas" v-else></div>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    props: ['compCode', 'baseQuery', 'visitId'],
    mixins: [myMixin],
    data() {
        return {
            nameKey: 'newCorpQuarter',
            tableName: 'sale_year',
            pageApi: 'company/saleYear/sumQuarterAmountGroupByComNameAndSpec',
            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug(),
                {
                    fieldName: 'unifySpec',
                    name: '规格',
                    width: 80,
                    listQueryView: "是",
                    listClass: 'tc',
                    listQueryModel: 1
                },
                ...this.$variable.quarter.map(item => ({
                    fieldName: `amount${item.newValue}`,
                    name: item.value,
                    // isZero: true,
                    listOrderView: '是',
                    width: 70,
                    listClass: 'tc'
                }))
            ]),
            active: '',
            error: '',
            canvas: {
                show: false,
                message: false,
                loading: false,
                list: [],
                model: {},
                code: 200,
            },

            loaded: false,
            realHeight: 100,
        }
    },
    created() {
        this.remoteMethod()
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
        this.loaded = true
    },
    methods: {
        remoteMethod(keyword) {
            this.canvas.loading = true
            this.$api.post(this.pageApi, {
                params: {
                    compCode: this.compCode,
                    comName: keyword ? `%${keyword}%` : undefined,
                },
                page: { pageNo: 1, pageSize: 20 }
            })
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.canvas.list = res.data.records.map(item => {
                            item.id = item.comCode = item.unifyDose + item.unifySpec
                            return item
                        })

                        if (!this.canvas.model.id && this.canvas.list.length !== 0) {
                            this.canvas.model = this.canvas.list[0]
                            this.showProduct()
                        }
                    } else {
                        this.canvas.message = true
                    }
                })
                .finally(() => {
                    this.canvas.loading = false
                })
        },
        showProduct() {
            const arr = this.$variable.quarter.map(item => {
                return {
                    quarter: item.value,
                    amount: this.canvas.model[`amount${item.newValue}`],
                    rate: this.$help.parseFloat100(this.canvas.model[`rate${item.newValue}`])
                }
            })
            // console.log(arr)
            this.$help.renderBar(this.nameKey, arr, 'quarter')
        },
        exportInfo(txt = '确定导出吗') {

            const search = this.getParam()
            delete search.sort
            delete search.page
            this.$help.exportInfo(txt, "company/saleYear/exportSumQuarterAmountAndRateGroupByComNameAndSpec", search)
        },
    }
}
</script>